// Notifications

@import '~bootstrap/scss/functions';
@import '../../../assets/styles/settings/bootstrap.variables';

$zindex-notification:               1080 !default;

.spacer {
  flex-grow: 1;
}

.notifications {
  position: fixed;
  top: 0; right: 0;
  margin: $grid-gutter-width/2;
  z-index: $zindex-notification;
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    max-width: 340px;
    min-width: 280px;

    background-color: $white;
    border-radius: $grid-gutter-width/16;
    border-left: .5rem solid $gray-300;
    box-shadow: $box-shadow-sm;

    color: $dark;

    padding: $grid-gutter-width/4 $grid-gutter-width/4 $grid-gutter-width/4 $grid-gutter-width/2;

    line-height: 1.4;

    & + .notifications-item {
      margin-top: $grid-gutter-width/4;
    }

    &-icon {
      align-self: center;

      color: $gray-300;
      font-size: 2rem;
    }

    &-title {
      font-size: 1rem;
      font-weight: 700;

      margin-bottom: .125rem;

      text-transform: capitalize;
    }

    &-message {
      color: $gray-600;
      font-size: .875rem;
      font-weight: 400;

      margin-bottom: 0;
    }

    .btn {
      color: $dark;

      margin-top: -$grid-gutter-width/4;
      margin-right: -$grid-gutter-width/4;
      &:hover {
        color: lighten($dark, 10%);
      }
    }

    &.is-danger {
      border-left-color: $danger;
      .notifications-item-icon {
        color: $danger;
      }
    }
    &.is-warning {
      border-left-color: $warning;
      .notifications-item-icon {
        color: $warning;
      }
    }
    &.is-success {
      border-left-color: $success;
      .notifications-item-icon {
        color: $success;
      }
    }
    &.is-info {
      border-left-color: $info;
      .notifications-item-icon {
        color: $info;
      }
    }
  }
}