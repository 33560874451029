// DataLoading

@import '~bootstrap/scss/functions';
@import '../../../../assets/styles/settings/bootstrap.variables';

.data-loading {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}
.data-loading {
  margin: 2rem auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: .25rem solid rgba($primary, 0.2);
  border-right: .25rem solid rgba($primary, 0.2);
  border-bottom: .25rem solid rgba($primary, 0.2);
  border-left: .25rem solid $primary;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes load8 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
