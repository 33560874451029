// Layouts: Header

.root-header {
  height: $grid-gutter-width*1.75;
  // transition: margin-left .3s ease-in-out;
  &.is-closed {
    margin-left: $grid-gutter-width*1.9;  
  }
  &.is-open {
    margin-left: $grid-gutter-width*8;
  }
}
