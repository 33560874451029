// Layouts: Footer

.root-footer {
  background-color: $dark;

  color: $white;
  font-size: .875rem;

  padding: $grid-gutter-width/4 $grid-gutter-width/2;

  text-align: center;
}
