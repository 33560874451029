// Layouts: Grid

.no-images {

}

.image-grid {
  display: flex;
  flex-wrap: wrap;

  margin-left: -2px;
  margin-right: -2px;
  &-main,
  &-nomain {
    flex: 1 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20rem;
    overflow: hidden;
  }
  &-main {
    border: 2px solid $white;
    text-align: center;
    img {
      width: 100%;
    }
  }
  &-nomain {
    background-color: $gray-300;
    color: $gray-500;
    font-size: 5rem;
  }
  &-loading,
  &-item {
    background-color: $gray-100;
    position: relative;
    border: 2px solid $white;

    flex: 1 0 33.33%;
    max-width: 33.33%;
    padding-top: 33.33%;

    cursor: pointer;
    overflow: hidden;
    &._active {
      border-color: $primary;
    }
    img {
      position: absolute;
      top: 50%; left: 50%;

      max-width: 100%;
      min-height: 100%;

      transform: translate(-50%, -50%);
    }
    button {
      background: none;
      border: 0;

      position: absolute;
      right: 0; bottom: 0;

      background-color: $danger;
      border-radius: .125rem;

      color: $white;
      font-size: .875rem;

      margin: .125rem;

      z-index: 2;
    }
  }
  &-loading {
    .wrap {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
