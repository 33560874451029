// Google map styles

.google-map-page {
  position: relative;
  width: 100%;
  height: calc(100vh - 178px);
}

.google-map-dialog {
  position: relative;
  width: 100%;
  height: 50vh;
}

.pac-container {
  z-index: 1071;
}

.control-search {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(black, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  left: 50%;
  margin-left: -120px;
  margin-top: 14px;
}

.button-location {
  box-sizing: border-box;
  border: 1px solid transparent;
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(black, 0.3);
  font-size: 14px;
  position: absolute;
  right: 0;
  bottom: 0;
  color: rgb(86, 86, 86);
  margin-bottom: 112px;
  margin-right: 10px;
}