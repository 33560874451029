// Layouts: Details

.details {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  // Overlay
  &-overlay {
    position: absolute;
    top: 0; right: 0;
    width: 100%;
    height: 100%;

    z-index: 1;
  }
  // Wrapper
  &-wrapper {
    position: absolute;
    top: 0; right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    @media screen and (min-width: map-get($grid-breakpoints, 'sm')){
      width: auto;
    }
  }
    // Container
    &-container {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      box-shadow: $box-shadow-sm;
      overflow-y: auto;
      padding: 1rem;
      z-index: 3;

      @media screen and (min-width: map-get($grid-breakpoints, 'sm')){
        width: 320px;
      }
      @media screen and (min-width: map-get($grid-breakpoints, 'md')){
        width: 480px;
      }
      @media screen and (min-width: map-get($grid-breakpoints, 'lg')){
        width: 540px;
      }
    }
}