// Components: Reservation

.reservation {
  display: flex;
  flex-direction: column;

  background-color: white;
  border: 1px solid $gray-300;

  &-head,
  &-body {
    display: flex;
  }
  &-head {
    border-bottom: 1px solid $gray-300;
  }
  &-body {
    
  }
    &-left {
      flex-shrink: 0;
      max-width: 25%;
      //width: 100%;
      border-right: 1px solid $gray-300;
    }
    &-right {
      flex-grow: 1;
      overflow-x: auto;
      overflow-y: hidden;
    }
}

.timeline {
  display: flex;
  &-item {
    position: relative;
    height: 2.5rem;
    ._day,
    ._number {
      display: block;
      text-align: center;
    }
    ._day {
      font-size: .75rem;
      color: $gray-600;
      font-weight: 700;
      line-height: 1.2;
    }
    ._number {
      font-weight: 700;
      line-height: 1.2;
    }
  }
}

.timeline-category {
  flex-direction: column;
  .timeline-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: .875rem;
    padding: .25rem .5rem;
    line-height: 1.2;
    & + .timeline-item {
      border-top: 1px solid $gray-300;
    }
  }
}

.timeline-day {
  & + .timeline-day {
    .timeline-item {
      border-top: 1px solid $gray-300;
    }
  }
  .timeline-item {
    flex: 1 0 0;
    min-width: 48px;
    & + .timeline-item {
      border-left: 1px solid $gray-300;
    }

    ._reservation,
    ._outOfService {
      display: flex;
      align-items: center;

      position: absolute;
      top: 0; left: 0; bottom: 0;

      cursor: pointer;

      color: white;
      font-size: .75rem;

      margin-top: 2px;
      margin-bottom: 2px;
      padding: 4px;
      z-index: 1;

      white-space: nowrap;
      overflow: hidden;
    }

    ._outOfService {
      background-color: $dark;

      margin-left: 2%;
    }
  }
}

.flex-direction-column {
  flex-direction: column;
}
