// Components: Forms

label { font-size: .875rem; font-weight: 700; }

.form-control.is-invalid { background: none; padding-right: 0.75rem; }
.form-control-sm.is-invalid { padding-right: .5rem; }

.image-upload {
  margin: 0 auto .5rem;
  &-preview {
    text-align: center;
    margin-bottom: .5rem;
    position: relative;
    & > img {
      max-width: 100%;
    }
    ._no-image {
      color: $gray-500;
      padding-top: 2rem;
      padding-bottom: 2rem;
      line-height: 1.2;
      text-align: center;
      & > .fa { font-size: 5rem; }
      & > .h5 { font-size: .875rem; margin-bottom: 0; }
    }
  }
  &-placeholder {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba($white, .875);

    display: flex;
    flex-direction: column;
    justify-content: center;
    > .fa {
      color: $primary;
      font-size: 2rem;
    }
  }
  .btn { max-width: 200px; margin-right: auto; margin-left: auto; }
}

.form-group-image-upload {
  input[type="file"] {
    position: absolute;
    top: -99999px;
    left: -9999px;
    visibility: hidden;
    opacity: 0;
  }
}
