// Components: Calendar

.calendar {
  display: flex;
  flex-direction: column;

  background-color: $white;
  border-radius: .25rem;
  box-shadow: $box-shadow-sm;

  margin-bottom: 1rem;
  padding: $grid-gutter-width/4;
  &-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-bottom: .5rem;
  }
  &-body {
    background-color: $white;
    border: 1px solid $gray-300;
  }
  &-foot {
    color: $dark;
    font-size: .875rem;
    font-weight: 700;

    padding-top: .25rem;
    padding-bottom: .25rem;

    text-align: center;
  }

  &-days {
    display: flex;
    flex-direction: row;

    background-color: $gray-100;
    border-bottom: 1px solid $gray-300;
    &-name {
      flex: 1 0 0;

      font-size: .75rem;
      font-weight: 500;

      padding-top: .5rem;
      padding-bottom: .5rem;

      text-align: center;
    }
  }

  &-week {
    display: flex;
    flex-direction: row;

    & + .calendar-week {
      border-top: 1px solid $gray-300;
    }
  }
  &-day {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex: 1 0 0;

    color: $gray-400;
    font-size: 1.125rem;
    font-weight: 400;

    padding: 1.5rem .5rem;

    &.samr-month {
      color: $dark;
      font-weight: 700;
    }
    &.samr-day {
      color: $primary;
      font-weight: 700;
    }
    &.selected-day {
      background-color: $primary;
      color: $white;
      font-weight: 700;
      &:hover {
        background-color: $primary;
      }
    }

    & + .calendar-day {
      border-left: 1px solid $gray-300;
    }

    &:hover {
      background-color: rgba($primary, .0875);
      cursor: pointer;
    }
  }
}
