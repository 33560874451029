// Layouts: Areas

.area {
  padding-top: $grid-gutter-width/2;
  padding-bottom: $grid-gutter-width/2;
  &-header {

  }
    &-title {
      border-bottom: 1px solid $gray-100;

      font-size: 1.25rem;
      font-weight: 700;

      padding-bottom: .5rem;
    }
  &-container {

  }
}

// Area auth
.area-auth {
  max-width: map-get($container-max-widths, 'xs');
  margin-right: auto;
  margin-left: auto;
  .area-container {
    background-color: $white;
    border-radius: .25rem;
    box-shadow: $box-shadow-sm;

    padding: $grid-gutter-width/4;
  }
}

// Area users
.area-users {
  max-width: map-get($container-max-widths, 'xxl');
  margin-right: auto;
  margin-left: auto;
}

// Area product form
.area-product-form {
  .area-container {
    max-width: map-get($container-max-widths, 'md');
    margin-right: auto;
    margin-left: auto;

    background-color: $white;
    border-radius: .25rem;
    box-shadow: $box-shadow-sm;

    padding: $grid-gutter-width/4;
  }
}

// Area orders
.area-orders {
  .area-container {
    max-width: map-get($container-max-widths, 'lg');
    margin-right: auto;
    margin-left: auto;

    background-color: $white;
    border-radius: .25rem;
    box-shadow: $box-shadow-sm;

    padding: $grid-gutter-width/4;
  }
}

// Area profile
.area-profile {
  max-width: map-get($container-max-widths, 'xs');
  margin-right: auto;
  margin-left: auto;
  .area-container {
    background-color: $white;
    border-radius: .25rem;
    box-shadow: $box-shadow-sm;

    padding: $grid-gutter-width/4;
  }
}

// Content item form
.area-form {
  .area-container {
    max-width: map-get($container-max-widths, 'sm');
    margin-right: auto;
    margin-left: auto;

    background-color: $white;
    border-radius: .25rem;
    box-shadow: $box-shadow-sm;

    padding: $grid-gutter-width/4;
  }
}

// Mail template form
.area-form-mail-template {
  .area-container {
    max-width: map-get($container-max-widths, 'lg');
  }
}
