// Components: Buttons

.btn-round {
  border: 0;
  padding: 0;

  width: 2rem;
  height: 2rem;
  border-radius: 50%;

  background-color: $gray-100;

  line-height: 1.2;
  &:hover {
    background-color: $gray-300;
  }
}
