// Components: Card

.card {
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-bottom: .5rem;

  border-color: transparent;
  box-shadow: $box-shadow-sm;

  &._checked {
    border-color: $success;
  }

  &-head {

  }

    &-image {
      padding-top: 56.25%;

      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border-top-right-radius: .25rem;
      border-top-left-radius: .25rem;

      position: relative;

      overflow: hidden;
    }
    &-no-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 200px;
      color: $gray-400;
      line-height: 1.2;
      text-align: center;
      & > .fa { font-size: 5rem; }
      & > .h5 { font-size: .875rem; margin-bottom: 0; }
    }
  &-body {
    flex-grow: 1;

    line-height: 1.2;

    padding: .5rem;
  }
  .card-foot {
    border-top: 1px solid $gray-300;
    padding: .5rem;
    text-align: right;
  }
}

// Product
.card-product {
  border: none;
  &._unavailable {
    .card-no-image,
    .card-image,
    .card-body,
    .card-foot {
      opacity: 0.75;
    }
  }

  .card-body {
    flex-grow: 1;

    line-height: 1.2;
  }
    .card-title {

    }
    .card-text {
      font-size: .875rem;
    }
}

// Content item
.card-content-item {
  max-width: map-get($container-max-widths, 'xs');
  margin-right: auto;
  margin-left: auto;
  border: none;
  &._move {
    .card-head {
      position: absolute;
      top: 0; left: 0; bottom: 0;
    }
  }
  & + .card-content-item {
    margin-top: 1rem;
  }
  &:hover {
    .card-move {
      opacity: 1;
      visibility: visible;
    }
  }
  .card-head {
    position: relative;
  }
    .card-move {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 0; left: 0; bottom: 0;

      background-image: linear-gradient(to right, $dark 0%, transparent);

      padding: .5rem;

      z-index: 2;

      @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
      }

      &-before,
      &-after {
        display: block;
        color: rgba($white, .75);
        font-size: 2rem;
        line-height: 1;
        &:hover {
          color: $white;
          cursor: pointer;
        }
      }
      &-before {
        margin-bottom: .5rem;
      }
      &_after {

      }
    }
    .card-image {
      background-size: cover;
    }
}
