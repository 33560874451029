// Layouts: Root

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-main {
    // display: flex;
    // flex-grow: 1;
  }
    &-container {
      flex-grow: 1;
      position: relative;
      margin-left: $grid-gutter-width*1.9;
      &.is-open {
        margin-left: $grid-gutter-width*8;
      }
    }
    // &-scroller {
    //   position: absolute;
    //   top: 0; right: 0;
    //   width: 100%;
    //   height: 100%;
    //   &-inner {
    //     position: relative;
    //     width: 100%;
    //     height: 100%;
    //     overflow-y: auto;
    //   }
    // }
}
