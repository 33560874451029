// DataMessage

@import '~bootstrap/scss/functions';
@import '../../../assets/styles/settings/bootstrap.variables';

.data-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: $gray-500;

  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;

  text-align: center;
  text-shadow: 0 1px 0 $white;
  &-icon {
    font-size: 6rem;
  }
  &-text {
    font-size: 2rem;
    font-weight: 400;
    padding-top: $grid-gutter-width/4;
  }
}
