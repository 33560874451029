/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
// @import '~bootstrap/scss/functions';
// @import './assets/styles/settings/bootstrap.variables';
// @import '~bootstrap/scss/mixins';
// @import '~bootstrap/scss/utilities';

// // Layout & components
// @import '~bootstrap/scss/root';
// @import '~bootstrap/scss/reboot';
// @import '~bootstrap/scss/type';
// @import '~bootstrap/scss/images';
// @import '~bootstrap/scss/containers';
// @import '~bootstrap/scss/grid';
// @import '~bootstrap/scss/tables';
// @import '~bootstrap/scss/forms';
// @import '~bootstrap/scss/buttons';
// @import '~bootstrap/scss/transitions';
// @import '~bootstrap/scss/dropdown';
// @import '~bootstrap/scss/button-group';
// @import '~bootstrap/scss/nav';
// @import '~bootstrap/scss/navbar';
// @import '~bootstrap/scss/card';
// @import '~bootstrap/scss/accordion';
// @import '~bootstrap/scss/breadcrumb';
// @import '~bootstrap/scss/pagination';
// @import '~bootstrap/scss/badge';
// @import '~bootstrap/scss/alert';
// @import '~bootstrap/scss/progress';
// @import '~bootstrap/scss/list-group';
// @import '~bootstrap/scss/close';
// @import '~bootstrap/scss/toasts';
// @import '~bootstrap/scss/modal';
// @import '~bootstrap/scss/tooltip';
// @import '~bootstrap/scss/popover';
// @import '~bootstrap/scss/carousel';
// @import '~bootstrap/scss/spinners';

// // Helpers
// @import '~bootstrap/scss/helpers';

// // Utilities
// @import '~bootstrap/scss/utilities/api';





// scss-docs-end import-stack
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import '~bootstrap/scss/functions';
@import './assets/styles/settings/bootstrap.variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

// Font awesome
@import './assets/styles/settings/font-awesome.variables';
@import './assets/styles/settings/font-awesome/mixins';
@import './assets/styles/settings/font-awesome/core';
@import './assets/styles/settings/font-awesome/larger';
@import './assets/styles/settings/font-awesome/fixed-width';
@import './assets/styles/settings/font-awesome/list';
@import './assets/styles/settings/font-awesome/bordered-pulled';
@import './assets/styles/settings/font-awesome/animated';
@import './assets/styles/settings/font-awesome/rotated-flipped';
@import './assets/styles/settings/font-awesome/stacked';
@import './assets/styles/settings/font-awesome/icons';
@import './assets/styles/settings/font-awesome/screen-reader';

@import '~react-phone-input-2/lib/bootstrap.css';


html, body {
  height: 100%;
  width: 100%;
}

body {
  background-color: $gray-100;
}

// Custom
// Settings
// Reboot
@import './assets/styles/reboot/fonts';
// Layout
@import './assets/styles/layouts/root';
@import './assets/styles/layouts/header';
@import './assets/styles/layouts/footer';
@import './assets/styles/layouts/drawer';
@import './assets/styles/layouts/areas';
@import './assets/styles/layouts/rows';
@import './assets/styles/layouts/container';
@import './assets/styles/layouts/details';
@import './assets/styles/layouts/grid';
@import './assets/styles/layouts/push-notification';
// Components
@import './assets/styles/components/button';
@import './assets/styles/components/calendar';
@import './assets/styles/components/cards';
@import './assets/styles/components/forms';
@import './assets/styles/components/reservation';
// States
.btn {
  white-space: nowrap;
}


.list-group {
  &-item {
    line-height: 1.2;
    &--left {

    }
    &--body {
      flex-grow: 1;
    }
    &--right {
      padding-left: 1rem;
    }
  }
}

.modal-backdrop-nested {
  z-index: $zindex-modal-backdrop + 10;
}

.modal-xxl {
  max-width: 1920px;
  padding-right: 1rem;
  padding-left: 1rem;;
}

.text-through { text-decoration: line-through; }


.bg-gray-100 { background-color: $gray-100; }
.bg-gray-200 { background-color: $gray-200; }
.bg-gray-300 { background-color: $gray-300; }
.bg-gray-400 { background-color: $gray-400; }
.bg-gray-500 { background-color: $gray-500; }
.bg-gray-600 { background-color: $gray-600; }
.bg-gray-700 { background-color: $gray-700; }


// Chat
.chat {
  display: flex;
  height: calc(100vh - 88px);

  border: 1px solid $gray-300;
}
.chat-users {
  display: flex;
  flex-direction: column;

  width: 33.33%;
  max-width: 400px;
  flex-shrink: 0;

  background-color: white;
  // padding: 8px;
  &-top {
    background-color: $gray-100;
    border-bottom: 1px solid $gray-300;
    padding: 8px;
  }
  &-middle {
    flex-grow: 1;
    padding: 8px;

    overflow-y: auto;
  }
}
.chat-conversation {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  border-left: 1px solid $gray-300;
  &-top {
    background-color: white;
    border-bottom: 1px solid $gray-300;
    padding: 8px;
  }
  &-middle {
    flex-grow: 1;
    padding: 8px;

    overflow-y: auto;
  }
}


// Conversation
.conversation {
  display: flex;
  flex-direction: column;
  &-divider {
    display: block;
    position: relative;
    text-align: center;
    &::before {
      content: '';
      position: absolute;
      left: 0; top: 50%;
      width: 100%;
      height: 1px;
      background-color: $gray-300;
      margin-top: 1px;
    }
    & > span {
      display: inline-block;
      position: relative;
      background-color: $gray-100;
      font-size: 12px;
      padding: 2px 8px;
      z-index: 1;
    }
  }
  &-item {
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: right;
    &.seen {
      background-color: $gray-100;
    }
    &.incoming {
      text-align: left;
      .conversation-item-message {
        background-color: $gray-400;
        color: $dark;
        & > span {
          left: auto; right: 0;
          margin-left: 0;
          margin-right: -8px;
          transform: translate(100%, -50%);
        }
      }
    }
    &-message {
      position: relative;

      display: inline-block;
      max-width: 70%;

      background-color: $primary;
      border-radius: 16px;

      color: white;
      font-size: 14px;

      padding: 8px 16px;
      & > span {
        display: inline-block;
        position: absolute;
        left: 0; top: 50%;

        color: $gray-600;
        font-size: 10px;

        margin-left: -8px;

        white-space: nowrap;

        transform: translate(-100%, -50%);
      }
    }
  }
}

.modal-payment-backdrop {
  z-index: 1051;
}
.modal-payment {
  z-index: 1052;
}

.sq-payment-form {
  width: 100% !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.modal-confirmation-backdrop {
  z-index: 1051;
}
.modal-confirmation {
  z-index: 1052;
}

// Editor 
.ck-content {
  min-height: 300px;
}

.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners {
  z-index: 1300;
}

.ck.ck-splitbutton>.ck-file-dialog-button.ck-button {
  display: none;
}
[dir=ltr] .ck.ck-splitbutton>.ck-splitbutton__arrow::before{
  content: '';
  width: 20px;
  height: 20px;
  background-image: url('http://simpleicon.com/wp-content/uploads/picture.svg');
  background-size: contain;
  margin-right: 5px;
}
