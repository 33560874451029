// Layouts: Push notifications

.push-notifications {
  position: absolute;
  left: 0; bottom: 0;
  margin: 1rem;

  z-index: 9;

  max-width: 400px;
  width: 100%;
  &-item {
    display: flex;
    align-items: stretch;

    background-color: $info;
    border: 1px solid darken($info, 5%);
    border-radius: .25rem;
    color: white;
    line-height: 1.2;
    overflow: hidden;
    & + .push-notifications-item {
      margin-top: 1rem;
    }
    &--body {
      flex-grow: 1;
      padding: 1rem;
      &.action {
        cursor: pointer;
        &:hover {
          background-color: darken($info, 2%);
        }
      }
    }
    &--action {
      display: flex;
      align-items: center;

      border-left: 1px solid darken($info, 5%);

      cursor: pointer;

      padding-left: 1rem;
      padding-right: 1rem;
      &:hover {
        background-color: darken($info, 2%);
      }
    }
    .title {
      font-weight: 700;
      margin-bottom: .5rem;
    }
    .body {
      font-size: .875rem;
      margin-bottom: 0;
    }
  }
}
